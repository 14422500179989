import Api from "@/services/Index";

const get = async (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/merge/sections",config);
}
const show = async (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/merge/sections/"+id);
}
const store = async(data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/merge/sections",data)
}
const update = async (id,data)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/merge/sections/"+id,data)
}
const del = async(id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/merge/sections/"+id)
}

export default {
    get,
    show,
    store,
    update,
    del
}

